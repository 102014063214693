//Imports
import './Styles/infototalseg.style.css';

//Assets
import TSLogoWhite from '../Assets/logos/logo-icon-white.webp';

const InfoTotalseg = () => {
    return (
        <div id="infototalseg">
            <div className='box-img-infototalseg'>
                <img src={TSLogoWhite} alt='Logo Totalseg' />
            </div>
            <div className='box-text-infototalseg'>
                <p>Fundada em 2013, somos uma empresa especializada na Cestão de Projetos de TI e distribuição de produtos na área de segurança eletrônica, TI, informática, telefonia, redes, automação, fibra óptica, controle de acesso nobreaks. Desenvolvemos sistemas inovadores, para instituições públicas e privadas, em todo Brasil.</p>
                <p>Soluções tecnológicas desenvolvidas de formas personalizadas com alto padrão de qualidade, agilidade e segurança.</p>
            </div>
        </div>        
    )
}

export default InfoTotalseg; 