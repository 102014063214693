
//Imports
import './Styles/Product.style.css';
import { Link, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import firebaseConfig from '../Services/FirebaseConfig';
import firebase from 'firebase/compat/app';
import LoadingElement from '../Components/LoadingElement/LoadingElement';
import whatsappNumber from '../Services/WhatsappNumber';

//Components
import CreateCardProducts from '../Components/CardProduct/CreateCardProduct';

//Assets
import { BsChevronCompactLeft } from "react-icons/bs";

//Configs
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const Product = () => {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [descrip1, setDescrip1] = useState('');
  const [descrip2, setDescrip2] = useState('');
  const [imagem, setImagem] =useState('');
  const [spec1, setSpec1] = useState('');
  const [specName1, setSpecName1] = useState('');
  const [spec2, setSpec2] = useState('');
  const [specName2, setSpecName2] = useState('');
  const [spec3, setSpec3] = useState('');
  const [specName3, setSpecName3] = useState('');
  const [spec4, setSpec4] = useState('');
  const [specName4, setSpecName4] = useState('');
  const [spec5, setSpec5] = useState('');
  const [specName5, setSpecName5] = useState('');
  const [spec6, setSpec6] = useState('');
  const [specName6, setSpecName6] = useState('');
  const [containerWidth, setContainerWidth] = useState(0);
  const [resultados, setResultados] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  });

  useEffect(() => {
    // Buscar os dados do documento existente
    const fetchProductData = async () => {
      try {
        const docRef = db.collection('products').doc(id);
        const doc = await docRef.get();
  
        if (doc.exists) {
          const productData = doc.data();
          if (productData.active) {
            setName(productData.name);
            setCategory(productData.category);
            setDescrip1(productData.descrip1);
            setDescrip2(productData.descrip2);
            setImagem(productData.img);
            setSpecName1(productData.specName1);
            setSpec1(productData.spec1);
            setSpecName2(productData.specName2);
            setSpec2(productData.spec2);
            setSpecName3(productData.specName3);
            setSpec3(productData.spec3);
            setSpecName4(productData.specName4);
            setSpec4(productData.spec4);
            setSpecName5(productData.specName5);
            setSpec5(productData.spec5);
            setSpecName6(productData.specName6);
            setSpec6(productData.spec6);
          } else {
            productNotFound();
          }
        } else {
          productNotFound();
        }
      } catch (error) {
        alert(`Erro ao buscar os dados: ${error}`);
      }
    };
    fetchProductData();
  }, [id]);

  useEffect(() => {
    const buscarResultados = async () => {
      try {
        const querySnapshot = await db.collection('products')
        .where('active', '==', true)
        .where('category', '==', category)
        .limit(10) // Adicionando um limite de 10 itens
        .get();
        const resultados = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setResultados(resultados.filter(resultado => resultado.id !== id));
      } catch (error) {
        alert(`Erro ao buscar resultados: ${error}`);
      }
    };
    buscarResultados();
  }, [category, id]);

  function renderMsgWhatsapp() {
    return (
      `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=Ol%C3%A1%2C%20Gostaria%20de%20um%20or%C3%A7amento%20para%20o%20item%3A%20${name}`
    )
  };

  function productNotFound() {
    document.querySelector('div.product-notfound').style.display = 'flex';
  };

  setTimeout(() => {
    document.querySelector('div.loading-product').style.display = 'none';
  }, 2000);

  const CreateListItem = ({ specName, SpesDescip }) => {
    return (
      <li className={ specName || SpesDescip ? 'spec-on' : 'spec-off' } > 
        <p> {specName} </p>
        <p> {SpesDescip} </p>
      </li>
    )
  }

  const handleNextClick = () => {
    containerRef.current.scrollLeft += containerWidth / 2;
  };

  const handlePrevClick = () => {
      containerRef.current.scrollLeft -= containerWidth / 2;
  };

  const handleContainerResize = () => {
      const containerDimensions = containerRef.current.getBoundingClientRect();
      setContainerWidth(containerDimensions.width);
  };

  return (
    <div id='Product'>
      <div className='name-box'>
        <p>{name}</p>
      </div>
      <div className='product-box'>
        <div className='img-box'>
          <img src={imagem} alt='Imagem de produto'/>
        </div>
        <div className='text-box'>
          <p>{descrip1}</p>
          <a href={ renderMsgWhatsapp() } target='_blanck'>Solicitar Orçamento</a>
        </div>
      </div>
      <div className='info-box'>
        <div className='name-box-info'>
          <p>Informações do produto</p>
        </div>
        <div className='texts-box-info'>
          <p className='tittle-product-info'>{name}</p>
          <p>{descrip2}</p>
        </div>
      </div>
      <div className='spec-box'>
        <div className='name-box-spec'>
          <p>Especificações técnicas</p>
        </div>
        <ul className='specs-box-spec'>
          <CreateListItem specName={specName1} SpesDescip={spec1}/>
          <CreateListItem specName={specName2} SpesDescip={spec2}/>
          <CreateListItem specName={specName3} SpesDescip={spec3}/>
          <CreateListItem specName={specName4} SpesDescip={spec4}/>
          <CreateListItem specName={specName5} SpesDescip={spec5}/>
          <CreateListItem specName={specName6} SpesDescip={spec6}/>
        </ul>
      </div>
          <div id='similar-products'>
            <h4>Produtos semelhantes</h4>
            <ul className='container' ref={containerRef} onLoad={handleContainerResize} onResize={handleContainerResize}>
              {resultados.map((resultado) => (
                <CreateCardProducts id={resultado.id} name={resultado.name} img={resultado.img} />
              ))}
            </ul>
              <button className='Navigation-btn-left' onClick={handlePrevClick}>
                <BsChevronCompactLeft className='btn-icon-navigation' />
              </button>
              <button className='Navigation-btn-right' onClick={handleNextClick}>
                <BsChevronCompactLeft className='btn-icon-navigation' />
              </button>
          </div>
        <LoadingElement />
      <div className='product-notfound'>
        <p>Produto não encontrado</p>
        <Link to='/'>Retornar a Home</Link>
      </div>
    </div>
  );
}
  
export default Product;