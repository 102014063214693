//Imports
import './Styles/register.style.css';
import { useState } from 'react';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { auth } from '../Services/FirebaseConfig';
import { useNavigate} from 'react-router-dom';

//Components
import ButtonHome from '../Components/ADM/ButtomHome/ButtomHome';

//Assets
import { AiOutlineUserAdd } from 'react-icons/ai';

const Register = () => {
  document.title = 'TotalSeg | ADM'
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [createUserWithEmailAndPassword] = useCreateUserWithEmailAndPassword(auth);

  function handleSignOut(e) {
    e.preventDefault();

    if (emailIsValid(email) && passwordIsValid(password)) {
      createUserWithEmailAndPassword(email, password).then(() => { navigate('/adm/home') });
    } else {
      alert('Verifique se os campos estão preenchidos')
    };
  };

  function emailIsValid(email) {
    if (email !== '') {
      return true
    };
  };

  function passwordIsValid(password) {
    if (password !== '') {
      return true
    };
  };

  return (
    <div className='container-login container-register'>
      <form onSubmit={handleSignOut} >
        <h2>Por favor digite suas informações de cadastro</h2>
        <input type='text' name='email' id='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} /> 
        <input type='password' name='password' id='password' placeholder='Senha' onChange={(e) => setPassword(e.target.value)} /> 
        <button type='submit'> Cadastrar <AiOutlineUserAdd /> </button>
      </form>
      <ButtonHome />
    </div>
  );
};

export default Register