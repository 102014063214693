import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBdk8XcCvHBu5xmobpZwpg2erH9BgyI0IQ",
  authDomain: "totalseg-34a6e.firebaseapp.com",
  projectId: "totalseg-34a6e",
  storageBucket: "totalseg-34a6e.appspot.com",
  messagingSenderId: "926105913374",
  appId: "1:926105913374:web:72d19aa1b24c0e93be060f",
  measurementId: "G-BSHW5XSCYW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default firebaseConfig;