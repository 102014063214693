//Imports
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Styles/AdmBanners.style.css';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import 'firebase/compat/firestore';
import firebaseConfig from '../Services/FirebaseConfig';
import firebase from 'firebase/compat/app';

//Components
import ButtonHome from '../Components/ADM/ButtomHome/ButtomHome';

//Assets
import { BiImageAdd } from "react-icons/bi";
import { BiTrash } from 'react-icons/bi';
import { BsPencil } from 'react-icons/bs';

//Configs
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const AdmBanners = () => {
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        // Função para consultar banners no Firebase
        const consultarBanners = async () => {
          try {
            const bannersRef = db.collection('banners');
            const bannersSnapshot = await bannersRef.get();
            const bannersData = bannersSnapshot.docs.map((doc) => doc.data());
            // Ordenar os resultados com base na propriedade "position"
            bannersData.sort((a, b) => a.position - b.position);

            setBanners(bannersData);
    
          } catch (error) {
            alert(`Erro ao consultar banners: ${error}`);
          }
        };
    
        consultarBanners();
     }, []);

     // Função para excluir um banner
    const excluirBanner = async (bannerId, imagemLink) => {
        const confirmarExclusao = window.confirm('Tem certeza de que deseja excluir este banner?');
        if (confirmarExclusao) {
        try {
            // Obter o nome do arquivo a partir do link da imagem
            const storage = getStorage();
            const imagemRef = ref(storage, imagemLink);
            // Excluir imagem do Storage
            await deleteObject(imagemRef);
            //Excluir banner
            await db.collection('banners').doc(bannerId).delete();
            // Remover o item excluído da lista de banners
            setBanners(banners.filter(banner => banner.id !== bannerId));
            alert('Banner excluído com sucesso!');
        } catch (error) {
            alert(`Erro ao excluir o banner: ${error}`);
        }
        }
    };
    
     const CreateCardBanner = ({ id, img, name, position }) => {
        return (
          <li className='card-item-banner' key={id}>
            <div className='img-box-banner'>
                <img src={img} alt= 'Imagem de Projeto'/>
            </div>
            <div className='title-box-banner'>
              <p className='texts-projects-banner'>{name}</p>
              <p className='texts-projects-banner'>Posição: {position}</p>
            </div>
            <div className='btn-box-banner'>
              <Link to={`/adm/edit-banner/${id}`}> <BsPencil /> </Link>
              <button onClick={() => excluirBanner(id, img)} > <BiTrash /> </button>
            </div>
          </li>
        )
      }

    return (
        <div id='adm-banners'>
            <Link to={'/adm/add-banner'} className='btn-add-banner'> <BiImageAdd/>Adicionar Banner</Link>
            <ul>
                {banners.map((banner) => (
                    <CreateCardBanner id={banner.id} img={banner.image} name={banner.name} position={banner.position} />
                ))}
            </ul>
            <ButtonHome />
        </div>
    )
}

export default AdmBanners;