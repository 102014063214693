//Imports
import './Styles/login.style.css';
import { useState } from 'react';
import { useSignInWithEmailAndPassword} from 'react-firebase-hooks/auth'
import { auth } from '../Services/FirebaseConfig';
import { useNavigate } from 'react-router-dom';

//Assets
import LogoTotalSeg from '../Assets/logos/logo-bg.webp'
import { BiLogIn } from 'react-icons/bi';


const Login = () => {
  document.title = 'TotalSeg | ADM';
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(auth);

  function handleSignIn(e) {
    e.preventDefault();
    if (emailIsValid(email) && passwordIsValid(password)) {
      signInWithEmailAndPassword(email, password).then((user) => {
        if (user) { 
          navigate('/adm/home');
        } else {
          alert('Login falhou. Verifique suas credenciais.');
          navigate('/adm/login');
        }
      });
    };
  };

  // Função de validação de e-mail
  function emailIsValid(email) {
    return true;
  };

  function passwordIsValid(password) {
    return true;
  };

  return (
    <div className='container-login'>
      <img src={LogoTotalSeg} alt='Logo Totalseg' />
      <form onSubmit={handleSignIn}>
        <p>Faça seu Login</p>
        <input type='text' name='email' id='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
        <input type='password' name='password' id='password' placeholder='Senha' onChange={(e) => setPassword(e.target.value)} />
        <button className='button' type='submit'> Entrar <BiLogIn /> </button>
      </form>
    </div>
  );
};

export default Login;