//Imports
import './Styles/admproduct.style.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import 'firebase/compat/firestore';
import firebaseConfig from '../Services/FirebaseConfig';
import firebase from 'firebase/compat/app';

//Assets
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { AiOutlineEye } from 'react-icons/ai';
import { AiOutlineClear } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import { BsPencil } from 'react-icons/bs';
import { RiProductHuntLine } from 'react-icons/ri';
import { AiOutlineStar } from 'react-icons/ai';

import logoWhite from '../Assets/logos/totalseg-white.png';

//Configs
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const AdmProducts = () => {
  document.title = 'TotalSeg | ADM';
  const [produtos, setProdutos] = useState([]);
  
  const filtroPadrao = { 
    filtro: 'todos',
    filtroCategoria: 'todos',
    filtroBusca: '',
    filtroDestaque: false, 
  };

  const [filtro, setFiltro] = useState(filtroPadrao.filtro);
  const [filtroCategoria, setFiltroCategoria] = useState(filtroPadrao.filtroCategoria);
  const [filtroBusca, setFiltroBusca] = useState(filtroPadrao.filtroBusca);
  const [filtroDestaque, setFiltroDestaque] = useState(filtroPadrao.filtroDestaque);

  const resetarFiltros = () => {
    setFiltro(filtroPadrao.filtro);
    setFiltroCategoria(filtroPadrao.filtroCategoria);
    setFiltroBusca(filtroPadrao.filtroBusca);
    setFiltroDestaque(filtroPadrao.filtroDestaque);
  };

  // Função para excluir um produto
  const excluirProduto = async (produtoId, imagemLink) => {
    const confirmarExclusao = window.confirm('Tem certeza de que deseja excluir este produto?');
    if (confirmarExclusao) {
      try {
        // Obter o nome do arquivo a partir do link da imagem
        const storage = getStorage();
        const imagemRef = ref(storage, imagemLink);
        // Excluir imagem do Storage
        await deleteObject(imagemRef);
        //Excluir produto
        await db.collection('products').doc(produtoId).delete();
        // Remover o item excluído da lista de produtos
        setProdutos(produtos.filter(produto => produto.id !== produtoId));
        alert('Produto excluído com sucesso!');
      } catch (error) {
        alert(`Erro ao excluir o produto: ${error}`);
      }
    }
  };

  useEffect(() => {
    // Função para consultar produtos no Firebase
    const consultarProdutos = async () => {
      try {
        const produtosRef = db.collection('products');
        const produtosSnapshot = await produtosRef.get();
        const produtosData = produtosSnapshot.docs.map((doc) => doc.data());
        setProdutos(produtosData);

      } catch (error) {
        alert(`Erro ao consultar produtos: ${error}`);
      }
    };

    consultarProdutos();
  }, []);
  
  const CreateCardProduct = ({ id, img, name, isActive, destaque }) => {
    return (
      <li className='card-item' key={id}>
          <div className='img-box'>
            { destaque ? <span className='destaque-products-adm'> <AiOutlineStar/> </span> : '' }
            <img src={img} alt= 'Imagem de Projeto'/>
          </div>
          <div className='title-box'>
          <p className='texts-projects'>{name}</p>
          </div>
          <div className='btn-box'>
          { isActive ? 
              <Link to={`/produto/${id}`} target='_blank' > <AiOutlineEye /> </Link> 
            : <span className='desactive'> <AiOutlineEyeInvisible /> </span> 
          }
          <Link to={`/adm/edit-product/${id}`}> <BsPencil /> </Link>
          <button onClick={() => excluirProduto(id, img)} > <BiTrash /> </button>
        </div>
      </li>
    )
  }

  return (
    <div className='adm-product'>
      <div className='nav-adm'>
        <Link to={'/adm/home'}><img src={logoWhite} alt='Logo TotalSeg' /></Link>
        <select value={filtro} onChange={(e) => setFiltro(e.target.value)}>
          <option value='todos'>Todos</option>
          <option value='ativos'>Ativos</option>
          <option value='inativos'>Inativos</option>
        </select>
        <select value={filtroCategoria} onChange={(e) => setFiltroCategoria(e.target.value)} className='select-category'>
        <option value='1'>-- Categoria --</option>
          <option>Infraestrutura</option>
          <option value='Armazenagem'>Armazenagem</option>
          <option value='Som-Ambiente'>Som Ambiente</option>
          <option value='Elétrica'>Elétrica</option>
          <option value='Telefonia'>Telefonia</option>
          <option value='Informática'>Informática</option>
          <option value='Controle-de-Acesso'>Controle de acesso</option>
          <option>CFTV</option>
          <option value='Centrais-de-alarme'>Centrais de alarme</option>
          <option>Catracas</option>
          <option>Cancelas</option>
          <option>Comunicação</option>
          <option value='Cercas-Elétricas'>Cercas Elétricas</option>
          <option>Redes</option>
          <option>Fibra</option>
          <option value='Casa-Inteligente'>Casa Inteligente</option>
          <option value='Automatizadores-de-Portão'>Automatizadores de Portão</option>
        </select>
         <label id='label-destaque'>
          { filtroDestaque ? 'Ver Todos' : 'Ver Destaques' }
          <input type='checkbox' checked={filtroDestaque} onChange={(e) => setFiltroDestaque(e.target.checked)}/>
         </label> 
        <input type='text' placeholder='🔎 Filtrar Produtos' value={filtroBusca} onChange={(e) => setFiltroBusca(e.target.value)} />
        <button onClick={resetarFiltros} className='btn-clear'> <AiOutlineClear /> </button>
        <Link to={'/adm/Add-product'}> <RiProductHuntLine /> Adicionar Produto </Link>
      </div>
      <ul>
        {produtos.filter(produto => {
          if (filtroCategoria === 'todos') {
            return true; // Exibir todos os produtos
          } else {
            return produto.category === filtroCategoria; // Filtrar por categoria selecionada
          }}).filter(produto => produto.name.toLowerCase().includes(filtroBusca.toLowerCase())).filter(produto => {
            if (filtro === 'ativos') {
              return produto.active; // Exibir somente produtos ativos
            } else if (filtro === 'inativos') {
              return !produto.active; // Exibir somente produtos inativos
            }
            return true; // Exibir todos os produtos
          }).filter((produto) => {
            if (filtroDestaque) {
              return produto.destaque; // Exibir somente produtos em destaque
            }
            return true; // Exibir todos os produtos
          }).map((produto) => (
            <CreateCardProduct id={produto.id} img={produto.img} name={produto.name} isActive={produto.active} destaque={produto.destaque}/>
          ))
        }
      </ul>
    </div>
  )
}
  
export default AdmProducts;