//Imports
import './Styles/Contact.style.css';
import { useState } from 'react';
//Assets
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";

//Configs
const linkToFetch = "https://formsubmit.co/totalseg@totalseges.com.br"

const Contact = () => {
    const [nameContact, setNameContact] = useState('');
    const [telContact, setTelContact] = useState('');
    const [emailContact, setEmailContact] = useState('');
    const [msgContact, setMsgContact] = useState('');

    const submitContact = (e) => {
        e.preventDefault();
        let BtnSContact = document.querySelector('button#btn-submit-contact');

        if ( nameIsValid(nameContact) && telIsValid(telContact) && emailIsValid (emailContact) && msgIsValid(msgContact) ) {
            BtnSContact.innerHTML = 'Enviando...'
            fetch(linkToFetch, {
              method: "post",
              headers: { "Accept": "aplication/json", "Content-Type": "application/json" },
              body: JSON.stringify({ 
                _captcha: 'false',
                _subject: 'Novo Contato no Site!',
                Nome: nameContact,
                Telefone: telContact,
                Email: emailContact,
                Mensagem: msgContact,
              })
            }).then(() => {
                BtnSContact.innerHTML = 'Enviado!'
              setTimeout(() => {
                BtnSContact.innerHTML = 'Enviar';
                setNameContact('');
                setTelContact('');
                setEmailContact('');
                setMsgContact('');
              }, 5000);
            })
        } else {
          BtnSContact.innerHTML = 'Preecha todos os campos'
          setTimeout(() => {
            BtnSContact.innerHTML = 'Enviar'
          }, 3000);
        }

    }

    const nameIsValid = (name) => {
        return name !== '';
    };

    const telIsValid = (tel) => {
        return tel !== '';
    };

    const emailIsValid = (email) => {
        return email !== '';
    };

    const msgIsValid = (msg) => {
        return msg !== '';
    };

    const CreateCardItem = ({icon, info}) => {
      return (
        <p className='addres-data'>
          <span className='adress-icon'>
            {icon}
          </span>
          {info}
        </p>
      )
    }

    return (
        <div id="contact">
           <h2>Entre em contato</h2>
           <form id='form-contact' onSubmit={submitContact}>
                <div className='form-contact-contacts'>
                    <input type='text' placeholder='Nome' value={nameContact} onChange={(e) => setNameContact(e.target.value)} />
                    <input type='tel' placeholder='Telefone' value={telContact} onChange={(e) => setTelContact(e.target.value)} />
                    <input type='email' placeholder='E-mail' value={emailContact} onChange={(e) => setEmailContact(e.target.value)} />
                </div>
                <textarea placeholder='Mensagem' value={msgContact} onChange={(e) => setMsgContact(e.target.value)} />
                <div className='form-contact-btn-bot'>
                    <button type='submit' id='btn-submit-contact'>Enviar</button>
                </div>
           </form>
           <div className='addres-box'>
               <div className='inside-addres'>
                  <h3 className='addres-tittle'>E-mail</h3>
                  <CreateCardItem icon={<AiOutlineMail/>} info={'totalseg@totalseges.com.br'} />
               </div>
               <div className='inside-addres'>
                  <h3 className='addres-tittle'>Telefone</h3>
                  <CreateCardItem icon={<AiOutlineWhatsApp/>} info={'(28)99991-6593'} />
                  <CreateCardItem icon={<BsTelephoneFill/>} info={'(28) 3517-3037'} />
               </div>
               <div className='inside-addres'>
                  <h3 className='addres-tittle'>Endereço</h3>
                  <p className='addres-data'>
                    <span className='adress-icon'>
                      <HiOutlineLocationMarker/>
                    </span>
                    Rua Jerônimo Ribeiro, 147 - Amarelo <br/> CEP 29304-632 - Cachoeiro de Itapemirim - ES
                  </p>
               </div>
           </div>
        </div>        
    )
}

export default Contact; 