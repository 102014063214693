//Imports
import { useEffect, useState } from 'react';
import './Styles/banner.style.css';
import firebaseConfig from '../Services/FirebaseConfig';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//Configs
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const Banner = () => {
    const [currentIdx, setCurrentIdx] = useState(0);
    const [images, setImages] = useState([])
    const time = 15000;

    useEffect(() => {
      const buscarResultados = async () => {
          try {
            const querySnapshot = await db.collection('banners').get();
            const resultados = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            resultados.sort((a, b) => a.position - b.position); // Ordenar os resultados com base na propriedade "position"
            setImages(resultados);
          } catch (error) {
            alert(`Erro ao buscar resultados: ${error}`);
          }
        };
        buscarResultados();
    }, []);

    const CreateImagemBanner = ({ link, image, idx }) => {
        return (
          <a href={link} key={idx}>
            <img src={image} alt={link} />
          </a>
        )
    }

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIdx((prevIdx) => (prevIdx + 1) % images.length);
      }, time);
    
      return () => clearInterval(interval);
     }, [images]);
    
    const handleButtonClick = (idx) => {
        setCurrentIdx(idx);
    };

    return (
      <div id="banner">
        <div className="carrosel-banner">
          <div className="container-carrosel-banner" style={{ transform: `translateX(${-currentIdx * 100}%)` }}>
                {images.map((image, idx) => (
                    <CreateImagemBanner link={image.link} image={image.image} idx={idx} />
                ))}
            </div>
        </div>
        <div className="banner-buttons">
            {images.map((_, idx) => (
                <button key={idx} className={`banner-button ${idx === currentIdx ? 'active' : ''}`} onClick={() => handleButtonClick(idx)} ></button>
            ))}
        </div>
      </div>
    );
  };
  
  export default Banner;