//Imports
import './Styles/Adm-clients.style.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import 'firebase/compat/firestore';
import firebaseConfig from '../Services/FirebaseConfig';
import firebase from 'firebase/compat/app';

//Components
import ButtonHome from '../Components/ADM/ButtomHome/ButtomHome';

//Assets
import { FaClinicMedical } from 'react-icons/fa';
import { BiTrash } from 'react-icons/bi';

//Configs
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const AdmClients = () => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    // Função para consultar clients no Firebase
    const consultarClients = async () => {
      try {
        const clientsRef = db.collection('clients');
        const clientsSnapshot = await clientsRef.get();
        const clientsData = clientsSnapshot.docs.map((doc) => doc.data());
        setClients(clientsData);

      } catch (error) {
        alert(`Erro ao consultar clients: ${error}`);
      }
    };

    consultarClients();
 }, []);

  // Função para excluir um client
  const excluirClient = async (clientId, imagemLink) => {
    const confirmarExclusao = window.confirm('Tem certeza de que deseja excluir este client?');
    if (confirmarExclusao) {
    try {
        // Obter o nome do arquivo a partir do link da imagem
        const storage = getStorage();
        const imagemRef = ref(storage, imagemLink);
        // Excluir imagem do Storage
        await deleteObject(imagemRef);
        //Excluir client
        await db.collection('clients').doc(clientId).delete();
        // Remover o item excluído da lista de clients
        setClients(clients.filter(client => client.id !== clientId));
        alert('Client excluído com sucesso!');
    } catch (error) {
        alert(`Erro ao excluir o client: ${error}`);
    }
    }
  };

  const CreateCardClient = ({ id, img, name }) => {
    return (
      <li className='card-item-client' key={id}>
        <div className='img-box-client'>
            <img src={img} alt= 'Imagem de Projeto'/>
        </div>
        <div className='title-box-client'>
          <p className='texts-projects-client'>{name}</p>
        </div>
        <div className='btn-box-client'>
          <button onClick={() => excluirClient(id, img)} > <BiTrash /> </button>
        </div>
      </li>
    )
  }

  return (
    <div id='Adm-clients'>
      <ul>
        {clients.map((client) => (
          <CreateCardClient id={client.id} img={client.image} name={client.name} />
        ))}
      </ul>
      <Link to={'/adm/add-client'} className='btn-add-client'> <FaClinicMedical/>Adicionar Cliente</Link>
      <ButtonHome />
    </div>
  )
}
  
export default AdmClients;