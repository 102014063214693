//Imports
import './Styles/info2totalseg.style.css';

//Assets

const Info2Totalseg = () => {
    return (
        <div id="info2totalseg">
           <div className='content-info2'>
                <h3>Nossos Serviços</h3>
                <p>A TOTALSEC atua na prestação de serviços técnicos especializados na manutenção preventiva e corretiva de equipamentos de Tl, monitoramento, cabeamento, configuração de rede lógica e wireless, servidores e consultoria de T l, dentro das normas e boas práticas do I TIL, COBIT, PMI e distribuição de produtos.</p>
           </div>
           <div className='content-info2'>
                <h3>Produtos</h3>
                <p>Oferecemos produtos de altíssima qualidade das melhores marcas nos segmentos de segurança eletrônica, Tl e informática.<br/>
                O nosso diferencial é a disponibilidade de estoque, o que nos permite realizar entregas rápidas e eficientes para atender às necessidades dos nossos clientes.</p>
           </div>
        </div>        
    )
}

export default Info2Totalseg; 