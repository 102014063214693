//Imports
import './Styles/EditProducts.style.css'
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import firebaseConfig from '../Services/FirebaseConfig';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

//Components
import { AiOutlineFileImage } from 'react-icons/ai';
import ButtonProducts from '../Components/ADM/ButtomHome/ButtomProducts';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { AiOutlineEye } from 'react-icons/ai';
import { AiOutlineStar } from 'react-icons/ai';

//Configs
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const EditProduct = () => {
  document.title = 'TotalSeg | ADM'
  const { id } = useParams();
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [descrip1, setDescrip1] = useState('');
  const [descrip2, setDescrip2] = useState('');
  const [active, setIsActive] = useState('');
  const [imagem, setImagem] =useState('')
  const [newImagem, setNewImagem] = useState(null);
  const [spec1, setSpec1] = useState('');
  const [specName1, setSpecName1] = useState('');
  const [spec2, setSpec2] = useState('');
  const [specName2, setSpecName2] = useState('');
  const [spec3, setSpec3] = useState('');
  const [specName3, setSpecName3] = useState('');
  const [spec4, setSpec4] = useState('');
  const [specName4, setSpecName4] = useState('');
  const [spec5, setSpec5] = useState('');
  const [specName5, setSpecName5] = useState('');
  const [spec6, setSpec6] = useState('');
  const [specName6, setSpecName6] = useState('');
  const [destaque, setDestaque] = useState(false);

  const handleImagemSelecionada = (event) => {
    if (event.target.files && event.target.files[0]) {
      setNewImagem(event.target.files[0]);
      document.querySelector('#label-file-text-edit').innerText = 'Nova imagem adicionada';
    } else {
      setNewImagem(null);
      document.querySelector('#label-file-text-edit').innerText = 'Adicionar nova imagem';
    }
  };
 
  useEffect(() => {
    // Buscar os dados do documento existente
    const fetchProductData = async () => {
      try {
        const docRef = db.collection('products').doc(id);
        const doc = await docRef.get();
        if (doc.exists) {
          const productData = doc.data();
          setName(productData.name);
          setCategory(productData.category);
          setDescrip1(productData.descrip1);
          setDescrip2(productData.descrip2);
          setIsActive(productData.active);
          setImagem(productData.img);
          setSpecName1(productData.specName1);
          setSpec1(productData.spec1);
          setSpecName2(productData.specName2);
          setSpec2(productData.spec2);
          setSpecName3(productData.specName3);
          setSpec3(productData.spec3);
          setSpecName4(productData.specName4);
          setSpec4(productData.spec4);
          setSpecName5(productData.specName5);
          setSpec5(productData.spec5);
          setSpecName6(productData.specName6);
          setSpec6(productData.spec6);
          setDestaque(productData.destaque)

        } else {
          alert('Produto não encontrado!');
        }
      } catch (error) {
        alert(`Erro ao buscar os dados: ${error}`);
      }
    };
    fetchProductData();
  }, [id]);
  
  // Função para atualizar os dados do documento
  const updateProduct = async (e) => {
    e.preventDefault();
    document.querySelector('#div-atualizando').style.display = 'flex';
    try {
      const docRef = db.collection('products').doc(id);
      // Atualize os campos desejados
      await docRef.update({
        name,
        descrip1,
        descrip2,
        category,
        active,
        spec1,
        specName1,
        spec2,
        specName2,
        spec3,
        specName3,
        spec4,
        specName4,
        spec5,
        specName5,
        spec6,
        specName6,
        destaque
      }).then( () => {
        try {
          if (newImagem) {
            const storage = getStorage();
            const imagemRef = ref(storage, imagem);
            // Excluir imagem do Storage
            deleteObject(imagemRef);
          }
        } catch (error) {
          alert(`Erro ao alterar imagem: ${error}`)
          return
        }
      }).then( ()=> {
        if (newImagem) {
          const storageRef = firebase.storage().ref();
          const nomeArquivo = new Date().getTime() + '-' + newImagem.name;
          const uploadTask = storageRef.child(nomeArquivo).put(newImagem);
          uploadTask.on( 
            'state_changed', null, (error) => {
              alert(`Erro; ${error}`);
            }, () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                try {
                  // Atualizar o documento com a URL da imagem
                  docRef.update({ img: downloadURL })
                  setImagem(downloadURL)
                } catch(error) {
                  alert(`Erro ao atualizar o documento com a URL da imagem: ${error}`);
                  return
                };
              });
            }
          );
        }
      }).then( () => {
        setTimeout(() => {
          document.querySelector('#label-file-text-edit').innerText = 'Adicionar nova imagem';
          document.querySelector('#div-atualizando').style.display = 'none';
          alert('Produto atualizado com sucesso!');
        }, 3000);
      })
    } catch (error) {
      alert(`Erro ao Atualizar o produto: ${error}`);
    }
  };

  return (
    <div className='container-edit-product'>
      <form onSubmit={updateProduct}>
        <h2>Editar Produto: {id}</h2>
        <div className='form-box'>
            <div>
              <input type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='Nome do Produto' />
              <select id='categorias' name='categorias' value={category}  onChange={(e) => setCategory(e.target.value)}>
                <option value='1'>-- Categoria --</option>
                <option>Infraestrutura</option>
                <option value='Armazenagem'>Armazenagem</option>
                <option value='Som-Ambiente'>Som Ambiente</option>
                <option value='Elétrica'>Elétrica</option>
                <option value='Telefonia'>Telefonia</option>
                <option value='Informática'>Informática</option>
                <option value='Controle-de-Acesso'>Controle de acesso</option>
                <option>CFTV</option>
                <option value='Centrais-de-alarme'>Centrais de alarme</option>
                <option>Catracas</option>
                <option>Cancelas</option>
                <option>Comunicação</option>
                <option value='Cercas-Elétricas'>Cercas Elétricas</option>
                <option>Redes</option>
                <option>Fibra</option>
                <option value='Casa-Inteligente'>Casa Inteligente</option>
                <option value='Automatizadores-de-Portão'>Automatizadores de Portão</option>
              </select>
              <textarea value={descrip1}  onChange={(e) => setDescrip1(e.target.value)} placeholder='Descrição do Produto: Paragrafo 1' />
              <textarea value={descrip2}  onChange={(e) => setDescrip2(e.target.value)} placeholder='Descrição do Produto: Paragrafo 2' />
              <div className='box-img-edit'>
              <a href={imagem} target='_blank' rel='noreferrer'> <AiOutlineFileImage /> </a>
                <label id='label-file-edit'>
                  <span id='label-file-text-edit'>Adicionar nova imagem</span>
                  <input type='file' id='input-file-edit' onChange={handleImagemSelecionada}/>
                </label>
              </div>
          </div>
          <div>
            <h3>Especificações</h3>
            <div>
              <input type='text' placeholder='Nome da Especificação' value={specName1} onChange={(e) => setSpecName1(e.target.value)} />
              <input type='text' placeholder='Valor da Especificação' value={spec1} onChange={(e) => setSpec1(e.target.value)}/>
            </div>
            <div>
              <input type='text' placeholder='Nome da Especificação' value={specName2} onChange={(e) => setSpecName2(e.target.value)} />
              <input type='text' placeholder='Valor da Especificação' value={spec2} onChange={(e) => setSpec2(e.target.value)}/>
            </div>
            <div>
              <input type='text' placeholder='Nome da Especificação' value={specName3} onChange={(e) => setSpecName3(e.target.value)} />
              <input type='text' placeholder='Valor da Especificação' value={spec3} onChange={(e) => setSpec3(e.target.value)}/>
            </div>
            <div>
              <input type='text' placeholder='Nome da Especificação' value={specName4}onChange={(e) => setSpecName4(e.target.value)} />
              <input type='text' placeholder='Valor da Especificação' value={spec4} onChange={(e) => setSpec4(e.target.value)}/>
            </div>
            <div>
              <input type='text' placeholder='Nome da Especificação' value={specName5} onChange={(e) => setSpecName5(e.target.value)} />
              <input type='text' placeholder='Valor da Especificação' value={spec5} onChange={(e) => setSpec5(e.target.value)}/>
            </div>
            <div>
              <input type='text' placeholder='Nome da Especificação' value={specName6} onChange={(e) => setSpecName6(e.target.value)} />
              <input type='text' placeholder='Valor da Especificação' value={spec6} onChange={(e) => setSpec6(e.target.value)}/>
            </div>
          </div>
        </div>
        <div className='btn-box-edit'>
          <label className= {active ?  '' : 'active-off-edit' }>
            {active ?  <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            <input type='checkbox' checked={active} onChange={(e) => setIsActive(e.target.checked)}/>
          </label>
          <button type='submit'>Salvar</button>
          <label className={destaque ? 'destaque-on-edit' : ''}>
            <AiOutlineStar />
            <input type='checkbox' checked={destaque} onChange={(e) => setDestaque(e.target.checked)}/>
          </label>
        </div>
      </form>
      <div id='div-atualizando'>
        <p>Atualizando produto, aguarde...</p>
      </div>
      <ButtonProducts />
    </div>
  );
};

export default EditProduct;