
//Imports
import './Styles/AddBanner.style.css';
import ButtomBanners from '../Components/ADM/ButtomHome/ButtomBanners';
import React, { useState } from 'react';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import firebaseConfig from '../Services/FirebaseConfig';
import firebase from 'firebase/compat/app';

//Configs
firebase.initializeApp(firebaseConfig);

const AddBanner = () => {
    const [name, setName] = useState('');
    const [link, setLink] = useState('');
    const [imagem, setImagem] = useState(null);
    const [position, setPosition] = useState(1);

    const handleImagemSelecionada = (event) => {
        if (event.target.files && event.target.files[0]) {
          setImagem(event.target.files[0]);
          document.querySelector('#label-file-text-add-banner').innerText = 'Imagem: anexada';
        } else {
          setImagem(null);
          document.querySelector('#label-file-text-add-banner').innerText = 'Imagem: não anexada';
        }
    };

      const addBanner = (event) => {
        event.preventDefault();
        if (name.trim() === '') {
          alert('O nome do banner não pode estar vazio');
          return;
        }
        if (imagem === null) {
          alert('A Imagem não pode estar vazia');
          return;
        }

        if (link === null) {
            alert('O Link não pode estar vazio');
            return;
        }
    
        const productsCollection = firebase.firestore().collection('banners');
        document.querySelector('#div-cadastrando-banner').style.display = 'flex';
    
        productsCollection.add({
          name: name,
          link: link,
          position: position

        }).then((docRef) => {
          let novoBannerId = docRef.id;
          docRef.update({ id: novoBannerId });
          // Upload da imagem
          if (imagem) {
            const storageRef = firebase.storage().ref();
            const nomeArquivo = new Date().getTime() + '-' + imagem.name;
            const uploadTask = storageRef.child(nomeArquivo).put(imagem);
            uploadTask.on( 
              'state_changed', null, (error) => {
                alert(`Erro; ${error}`);
                cleanerForm();
              }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  // Atualizar o documento com a URL da imagem
                  docRef.update({ image: downloadURL }).then(() => {
                    alert(`Banner adicionado`);
                    cleanerForm();
                  }).catch((error) => {
                    alert(`Erro ao atualizar o documento com a URL da imagem: ${error}`);
                    cleanerForm();
                  });
                });
              }
            )};
          }).catch((error) => {
            alert(`Erro ao adicionar o Banner: ${error}`);
            cleanerForm();
          });
      };
    
      const cleanerForm = () => {
        // Limpar o formulário
        setName('');
        setLink('');
        setImagem(null);
        document.querySelector('#label-file-text-add-banner').innerText = 'Imagem: não anexada';
        document.querySelector('#div-cadastrando-banner').style.display = 'none';
        document.querySelector('form#form-add-banner').reset();
      };

    return (
        <div id='add-banner'>
            <form onSubmit={addBanner} id='form-add-banner'>
                <p>Adicionar Banner</p>
                <input type='text' onChange={(e) => setName(e.target.value)} placeholder='Nome do banner' />
                <input type='text' onChange={(e) => setLink(e.target.value)} placeholder='Link de Destino' />
                <div>
                  <label>
                      <span id='label-file-text-add-banner'>Imagem: não anexada</span>
                      <input type='file' onChange={handleImagemSelecionada} id='input-file-text-add-banner' />
                  </label>
                  <input className='input-number' value={position} type='number' min={1} onChange={(e) => setPosition(e.target.value)} placeholder='Posição' />
                </div>
                <button type='submit'>Adicionar Banner</button>
            </form>
            <div id='div-cadastrando-banner'>
                <p>Cadastrando Banner, aguarde...</p>
            </div> 
           <ButtomBanners />
        </div>
    )
}

export default AddBanner;