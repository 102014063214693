//Imports
import './Styles/Adm.style.css';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import 'firebase/compat/firestore';

//Assets
import { AiOutlineUserAdd } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';
import { RiProductHuntLine } from 'react-icons/ri';
import { BsImages } from 'react-icons/bs';
import { FaClinicMedical } from 'react-icons/fa';


import logoWhite from '../Assets/logos/totalseg-white.png';

const Adm = () => {
  document.title = 'TotalSeg | ADM';
  const navigate = useNavigate();
  
  // Função para Logout
  const fazerLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => { 
      navigate('/adm/login');
    });
  };

  const CreateCardItem = ({ link, img, name }) => {
    return (
      <Link to={link}> {img} {name} </Link>
    )
  }

  return (
    <div id='Adm'>

        <CreateCardItem link={'/adm/banners'} img={<BsImages />} name={'Banners'} />
        <CreateCardItem link={'/adm/clients'} img={<FaClinicMedical />} name={'Clientes'} />
        <CreateCardItem link={'/adm/register'} img={<AiOutlineUserAdd />} name={'Novo Usuário'} />
        <CreateCardItem link={'/adm/products'} img={<RiProductHuntLine />} name={'Produtos'} />

        <img src={logoWhite} alt='Logo' className='logowhite' />
        <button onClick={fazerLogout}> <FiLogOut /> Logout </button>
    </div>
  )
}
  
export default Adm;