import { Link } from "react-router-dom";
import './CreateCardProduct.style.css';

const CreateCardProducts = ({id, name, img}) => {
    return (
      <li key={id} className='box-item-product'>
        <Link to={`/produto/${id}`} className='link-item-product'>
          <div className='box-img-itens-product'>
            <img src={img} alt='Imagem de produto' className='img-item-product' />
          </div>
          <p className='name-item-product'>{name}</p>
        </Link>
      </li>
   )
}

export default CreateCardProducts;