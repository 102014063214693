//Imports
import './Styles/info3totalseg.style.css';

//Assets
import imgAcessoRemoto from '../Assets/imgs/Acesso-Remoto.webp'
import imgTI from '../Assets/imgs/TI.webp'
import imgIformatica from '../Assets/imgs/Informática.webp'
import imgCabeamento from '../Assets/imgs/Cabeamento-Estruturado.webp'
import imgRede from '../Assets/imgs/Rede-Lógica.webp'
import imgHelpDesk from '../Assets/imgs/Help-Desk.webp'

const Info3Totalseg = () => {
    
    const CreateCardInfo3 = ({ title, img, text }) => {
        return (
            <div className='card-info3'>
                <div className='img-box-info3'>
                    <img src={img} alt='Imagem de suporte' />
                </div>
                <div className='title-box-info3'>
                    <h3>{title}</h3>
                </div>
                <div className='text-box-info3'>
                    <p>{text}</p>
                </div>
            </div>
        )
    }
    
    return (
        <div id="info3totalseg">
            <div>
                <CreateCardInfo3 title={'Acesso Remoto'} img={imgAcessoRemoto} text={'Temos profissionais capacitados para acesso remoto. Possibilitando atendimento mais rápido, redução de custos, clientes mais satisfeitos e otimização da produtividade na equipe de atendimento.'} />

                <CreateCardInfo3 title={'Ti - Tecnologia da Informação'} img={imgTI} text={'Temos profissionais capacitados responsáveis por gerenciar todos os dados de fontes internas e de uma empresa e compilando em softwares, sites ou banco de dados.'} />

                <CreateCardInfo3 title={'Informática'} img={imgIformatica} text={'Na área de informática, comercializamos computadores e notebooks programas (softwares) originais, e temos também, todo tipo de peças para PCs e notebooks.'} />

                <CreateCardInfo3 title={'Cabeamento Estruturado'} img={imgCabeamento} text={'Realizamos projeto de cabeamento estruturado de forma organizada, padronizada para alcançar melhor desempenho. Atuamos de acordo com as normas de segurança e as melhores práticas do ETIL, COBIT e PMI.'} />

                <CreateCardInfo3 title={'Rede Lógica'} img={imgRede} text={'Contamos com uma equipe para reparo, planejamento e desenvolvimento de rede lógica estruturada, observando as especificações técnicas necessárias e seguindo normas para que se tenham condições para a organização de um cabeamento estruturado, possibilitando a melhor infraestrutura de rede física.'} />

                <CreateCardInfo3 title={'Help Desk'} img={imgHelpDesk} text={'Contamos com profissionais capacitados disponíveis para atender solicitações, esclarecer dúvidas e fornecer soluções para problemas técnicos relacionados a informática, telefonia e tecnologias de informação. Além disso, oferecemos suporte pré e pós-vendas, garantindo a resolução dos serviços prestados.'} />
            </div>
        </div>        
    )
}

export default Info3Totalseg; 