//Assets

//Imports
import './Styles/all-products.css';
import { useEffect, useState } from 'react';
import firebaseConfig from '../Services/FirebaseConfig';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//Components/Sections
import CreateCardProducts from '../Components/CardProduct/CreateCardProduct';

//Configs
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const AllProducts = () => {
  const [resultados, setResultados] = useState([]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  });

  useEffect(() => {
    const buscarResultados = async () => {
      try {
        const querySnapshot = await db.collection('products')
          .where('active', '==', true)
          .get();
        const resultados = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setResultados(resultados);
      } catch (error) {
        alert(`Erro ao buscar resultados: ${error}`);
      }
    };
    buscarResultados();
  }, []);

    return (
      <div id='AllProducts'>
        <h2>Todos os Produtos</h2>
        <ul className='container-produtos'>
          {resultados.map((resultado) => (
            <CreateCardProducts id={resultado.id} name={resultado.name} img={resultado.img} />
          ))}
        </ul>
      </div>
    );
}
  
export default AllProducts;