import './Styles/style-PageNotFound.css'
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    document.title = 'TotalSeg | Página não encontrada!'

    return (
        <div id='PageNotFound'>
           <h2>Opa! Não encontramos a página que está tentando acessar!</h2>
           <Link to={'/'}>Retornar a Home</Link>
        </div>
    )
}

export default PageNotFound;