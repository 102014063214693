//Assets

//Imports
import './Styles/SearchPage.style.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import firebaseConfig from '../Services/FirebaseConfig';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import CreateCardProducts from '../Components/CardProduct/CreateCardProduct';

//Components/Sections

//Configs
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const SearchPage = () => {
  const { termo } = useParams();
  const [resultados, setResultados] = useState([]);
  
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };

    scrollToTop();
  });

  useEffect(() => {
    const buscarResultados = async () => {
      try {
        const querySnapshot = await db.collection('products').get();
        const resultados = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(resultado => resultado.name.toLowerCase().includes(termo.toLowerCase()) && resultado.active);
        setResultados(resultados);
      } catch (error) {
        alert(`Erro ao buscar resultados: ${error}`);
      };
    };
    buscarResultados();
  }, [termo]);

  return (
    <div id='SearchPage'>
      <p className='title-search'>Sua busca foi: {termo}</p>
      { resultados.length > 0 ?
        <ul className='box-itens-search'>
          {resultados.map((resultado) => (
            <CreateCardProducts id={resultado.id} name={resultado.name} img={resultado.img} />
          ))}
        </ul>
      :
        <div className='nenhum-produto'>
            <p>Nenhum produto encontrado</p>
        </div>
      }
    </div>
  );
}

export default SearchPage;