//Assets

//Imports
import './Styles/Home.style.css';
import { useEffect } from 'react';

//Components/Sections
import Clients from '../Sections/Clients';
import InfoTotalseg from '../Sections/InfoTotalseg';
import Info2Totalseg from '../Sections/Info2Totalseg';
import Info3Totalseg from '../Sections/Info3Totalseg';
import Contact from '../Sections/Contact';
import ProductsHome from '../Sections/ProductsHome';
import Banner from '../Sections/Banner';

const Home = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  });

    return (
      <div id='Home'>
        <Banner />
        <InfoTotalseg />
        <Info2Totalseg />
        <Info3Totalseg />
        <ProductsHome />
        <Contact />
        <Clients />
      </div>
    );
}
  
export default Home;