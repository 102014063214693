
//Imports
import './Styles/EditBanner.style.css';
import ButtomBanners from '../Components/ADM/ButtomHome/ButtomBanners';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import firebaseConfig from '../Services/FirebaseConfig';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

//Configs
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const EditBanner = () => {
    const { id } = useParams();
    const [name, setName] = useState('');
    const [imagem, setImagem] =useState('');
    const [link, setLink] =useState('');
    const [position, setPosition] = useState('');

    useEffect(() => {
        // Buscar os dados do documento existente
        const fetchBannerData = async () => {
          try {
            const docRef = db.collection('banners').doc(id);
            const doc = await docRef.get();
            if (doc.exists) {
              const bannerData = doc.data();
              setName(bannerData.name);
              setImagem(bannerData.image);
              setLink(bannerData.link)
              setPosition(bannerData.position)
            } else {
              alert('Banner não encontrado!');
            }
          } catch (error) {
            alert(`Erro ao buscar os dados: ${error}`);
          }
        };
        fetchBannerData();
      }, [id]);

     // Função para atualizar os dados do documento
    const updateBanner = async (e) => {
        e.preventDefault();
        document.querySelector('#div-att-banner').style.display = 'flex';
        try {
        const docRef = db.collection('banners').doc(id);
        // Atualize os campos desejados
        await docRef.update({
            name,
            link,
            position,
        }).then( () => {
            setTimeout(() => {
                alert('Banner atualizado com sucesso!');
                document.querySelector('#div-att-banner').style.display = 'none';
            }, 3000);
        })
        } catch (error) {
            alert(`Erro ao Atualizar o banner: ${error}`);
            document.querySelector('#div-att-banner').style.display = 'none';
        }
    };

    return (
        <div id='edit-banner'>
             <form onSubmit={updateBanner} id='form-att-banner'>
                <p>Atualizar Banner</p>
                <div className='box-img-att-banner'>
                    <img src={imagem} alt='Imagem de produto'/>
                </div>
                <input type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='Nome do banner' />
                <input type='text' value={link} onChange={(e) => setLink(e.target.value)} placeholder='Link de Destino' />
                <input className='input-number' value={position} type='number' min={1} onChange={(e) => setPosition(e.target.value)} placeholder='Posição' />
                <button type='submit'>Atualizar Banner</button>
            </form>
            <div id='div-att-banner'>
                <p>Atualizando banner, aguarde...</p>
            </div>
           <ButtomBanners />
        </div>
    )
}

export default EditBanner;