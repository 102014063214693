//Imports
import './Styles/clients.style.css';
import { useEffect, useState } from 'react';
import firebaseConfig from '../Services/FirebaseConfig';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//Assets

//Configs
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const Clients = () => {
    const [clients, setClients] = useState([]);

    useEffect(() => {
        const buscarClients = async () => {
          try {
            const clientsRef = db.collection('clients');
            const clientsSnapshot = await clientsRef.get();
            const clientsData = clientsSnapshot.docs.map((doc) => doc.data());
            setClients(clientsData);
          } catch (error) {
            console.log(`Erro ao buscar resultados: ${error}`);
          };
        };
        buscarClients();
      }, []);

      const CreateCardClient = ({img, name}) => {
        return (
            <li key={name}>
                <img src={img} alt={name} />
            </li>
        )
      }

    return (
        <div id="clients">
            <h2>Clientes</h2>
            <ul className='box-clients-img'>
            {clients.map((client) => (
                <CreateCardClient img={client.image} name={client.name} />
            ))}   
            </ul>
        </div>        
    )
}

export default Clients; 