//Imports
import './Styles/AddProducts.style.css';
import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import firebaseConfig from '../Services/FirebaseConfig';

//Components
import ButtonProducts from '../Components/ADM/ButtomHome/ButtomProducts';
import { AiOutlineStar } from 'react-icons/ai';

//Configs
firebase.initializeApp(firebaseConfig);

const Addproducts = () => {
  document.title = 'TotalSeg | ADM';
  const [name, setName] = useState('');
  const [description1, setDescription1] = useState('');
  const [description2, setDescription2] = useState('');
  const [category, setCategory] = useState('1');
  const [imagem, setImagem] = useState(null);
  const [destaque, setDestaque] = useState(false);

  const [specName1, setSpecName1] = useState('');
  const [spec1, setSpec1] = useState('');

  const [specName2, setSpecName2] = useState('');
  const [spec2, setSpec2] = useState('');

  const [specName3, setSpecName3] = useState('');
  const [spec3, setSpec3] = useState('');

  const [specName4, setSpecName4] = useState('');
  const [spec4, setSpec4] = useState('');

  const [specName5, setSpecName5] = useState('');
  const [spec5, setSpec5] = useState('');

  const [specName6, setSpecName6] = useState('');
  const [spec6, setSpec6] = useState('');

  const handleImagemSelecionada = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImagem(event.target.files[0]);
      document.querySelector('#label-file-text').innerText = 'Imagem: anexada';
    } else {
      setImagem(null);
      document.querySelector('#label-file-text').innerText = 'Imagem: não anexada';
    }
  };

  const addProduct = (event) => {
    event.preventDefault();
    if (name.trim() === '') {
      alert('O nome do produto não pode estar vazio');
      return;
    }
    if (imagem === null) {
      alert('A Imagem não pode estar vazia');
      return;
    }

    const productsCollection = firebase.firestore().collection('products');
    document.querySelector('#div-cadastrando').style.display = 'flex';

    productsCollection.add({
      active: true,
      destaque: destaque,
      name: name,
      category: category,
      descrip1: description1,
      descrip2: description2,
      specName1: specName1,
      spec1: spec1,
      specName2: specName2,
      spec2: spec2,
      specName3: specName3,
      spec3: spec3,
      specName4: specName4,
      spec4: spec4,
      specName5: specName5,
      spec5: spec5,
      specName6: specName6,
      spec6: spec6,
    }).then((docRef) => {
      let novoProdutoId = docRef.id;
      docRef.update({ id: novoProdutoId });
      // Upload da imagem
      if (imagem) {
        const storageRef = firebase.storage().ref();
        const nomeArquivo = new Date().getTime() + '-' + imagem.name;
        const uploadTask = storageRef.child(nomeArquivo).put(imagem);
        uploadTask.on( 
          'state_changed', null, (error) => {
            alert(`Erro; ${error}`);
            cleanerForm();
          }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              // Atualizar o documento com a URL da imagem
              docRef.update({ img: downloadURL }).then(() => {
                alert(`Produto adicionado com ID: ${docRef.id}`);
                cleanerForm();
              }).catch((error) => {
                alert(`Erro ao atualizar o documento com a URL da imagem: ${error}`);
                cleanerForm();
              });
            });
          }
        );
        } else {
          alert(`Produto adicionado com ID: ${docRef.id}, porém sem foto.`);
          cleanerForm();
        }
      }).catch((error) => {
        alert(`Erro ao adicionar o produto: ${error}`);
        cleanerForm();
      });
  };

  const cleanerForm = () => {
    // Limpar o formulário
    setName('');
    setCategory('1');
    setDescription1('');
    setDescription2('');
    setImagem(null);
    setSpecName1('');
    setSpecName2('');
    setSpecName3('');
    setSpecName4('');
    setSpecName5('');
    setSpecName6('');
    setDestaque(false);
    document.querySelector('#label-file-text').innerText = 'Imagem: não anexada';
    document.querySelector('#div-cadastrando').style.display = 'none';
    document.querySelector('form#form-addproduct').reset();
  };

  return (
    <div className='container-add-product'>
      <form onSubmit={addProduct} id='form-addproduct'>
        <h2>Adicionar Produto</h2>
        <div className='form-box'>
          <div>
            <input type='text' onChange={(e) => setName(e.target.value)} placeholder='Nome do produto' />
            <select id='categorias' name='categorias' value={category} onChange={(e) => setCategory(e.target.value)}>
              <option value='1'>-- Categoria --</option>
              <option>Infraestrutura</option>
              <option value='Armazenagem'>Armazenagem</option>
              <option value='Som-Ambiente'>Som Ambiente</option>
              <option value='Elétrica'>Elétrica</option>
              <option value='Telefonia'>Telefonia</option>
              <option value='Informática'>Informática</option>
              <option value='Controle-de-Acesso'>Controle de acesso</option>
              <option>CFTV</option>
              <option value='Centrais-de-alarme'>Centrais de alarme</option>
              <option>Catracas</option>
              <option>Cancelas</option>
              <option>Comunicação</option>
              <option value='Cercas-Elétricas'>Cercas Elétricas</option>
              <option>Redes</option>
              <option>Fibra</option>
              <option value='Casa-Inteligente'>Casa Inteligente</option>
              <option value='Automatizadores-de-Portão'>Automatizadores de Portão</option>
            
            </select>
            <textarea onChange={(e) => setDescription1(e.target.value)} placeholder='Descrição do Produto: Paragrafo 1' />
            <textarea value={description2} onChange={(e) => setDescription2(e.target.value)} placeholder='Descrição do Produto: Paragrafo 2' />
            <label id='label-file-add'>
              <span id='label-file-text'>Imagem: não anexada</span>
              <input type='file' onChange={handleImagemSelecionada} id='input-file-add' />
            </label>
          </div>
          <div className='specs-box-add'>
            <h3>Especificações</h3>
            <div>
              <input type='text' onChange={(e) => setSpecName1(e.target.value)} placeholder='Nome da Especificação' />
              <input type='text' onChange={(e) => setSpec1(e.target.value)} placeholder='Valor da Especificação' />
            </div>
            <div>
              <input type='text' onChange={(e) => setSpecName2(e.target.value)} placeholder='Nome da Especificação' />
              <input type='text' onChange={(e) => setSpec2(e.target.value)} placeholder='Valor da Especificação' />
            </div>
            <div>
              <input type='text' onChange={(e) => setSpecName3(e.target.value)} placeholder='Nome da Especificação' />
              <input type='text' onChange={(e) => setSpec3(e.target.value)} placeholder='Valor da Especificação' />
            </div>
            <div>
              <input type='text' onChange={(e) => setSpecName4(e.target.value)} placeholder='Nome da Especificação' />
              <input type='text' onChange={(e) => setSpec4(e.target.value)} placeholder='Valor da Especificação' />
            </div>
            <div>
              <input type='text' onChange={(e) => setSpecName5(e.target.value)} placeholder='Nome da Especificação' />
              <input type='text' onChange={(e) => setSpec5(e.target.value)} placeholder='Valor da Especificação' />
            </div>
            <div>
              <input type='text' onChange={(e) => setSpecName6(e.target.value)} placeholder='Nome da Especificação' />
              <input type='text' onChange={(e) => setSpec6(e.target.value)} placeholder='Valor da Especificação' />
            </div>
          </div>
        </div>
        <div className='btn-box-add'>
          <button type='submit'>Adicionar Produto</button>
          <label className={destaque ? 'destaque-on-add' : ''}>
            <AiOutlineStar />
            <input type='checkbox' checked={destaque} onChange={(e) => setDestaque(e.target.checked)}/>
          </label>
        </div>
      </form>
      <ButtonProducts />
      <div id='div-cadastrando'>
        <p>Cadastrando produto, aguarde...</p>
      </div>
    </div>
  );
};
  
export default Addproducts;