//Imports
import './Styles/add-clients.style.css';
import ButtomClients from '../Components/ADM/ButtomHome/ButtomClients';
import React, { useState } from 'react';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import firebaseConfig from '../Services/FirebaseConfig';
import firebase from 'firebase/compat/app';

//Assets

//Configs
firebase.initializeApp(firebaseConfig);

const AddClient = () => {
  const [imagem, setImagem] = useState(null);
    const [name, setName] = useState('');

  const handleImagemSelecionada = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImagem(event.target.files[0]);
      document.querySelector('#label-file-text-add-client').innerText = 'Imagem: anexada';
    } else {
      setImagem(null);
      document.querySelector('#label-file-text-add-client').innerText = 'Imagem: não anexada';
    }
  };

  const AddClient = (event) => {
    event.preventDefault();
    if (name.trim() === '') {
      alert('O nome do client não pode estar vazio');
    return;
    }
    if (imagem === null) {
      alert('A Imagem não pode estar vazia');
      return;
    }

    const productsCollection = firebase.firestore().collection('clients');
    document.querySelector('#div-cadastrando-client').style.display = 'flex';
    
    productsCollection.add({
      name: name,

    }).then((docRef) => {
      let novoClientId = docRef.id;
      docRef.update({ id: novoClientId });
      // Upload da imagem
      if (imagem) {
        const storageRef = firebase.storage().ref();
        const nomeArquivo = new Date().getTime() + '-' + imagem.name;
        const uploadTask = storageRef.child(nomeArquivo).put(imagem);
        uploadTask.on( 
        'state_changed', null, (error) => {
          alert(`Erro; ${error}`);
          cleanerForm();
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              // Atualizar o documento com a URL da imagem
              docRef.update({ image: downloadURL }).then(() => {
                alert(`Client adicionado`);
                cleanerForm();
              }).catch((error) => {
                alert(`Erro ao atualizar o documento com a URL da imagem: ${error}`);
                cleanerForm();
              });
            });
          }
        )
      };
    }).catch((error) => {
      alert(`Erro ao adicionar o Client: ${error}`);
      cleanerForm();
    });
  };
    
  const cleanerForm = () => {
    // Limpar o formulário
    setName('');
    setImagem(null);
    document.querySelector('#label-file-text-add-client').innerText = 'Imagem: não anexada';
    document.querySelector('#div-cadastrando-client').style.display = 'none';
    document.querySelector('form#form-add-client').reset();
  };

  return (
    <div id='add-client'>

      <form onSubmit={AddClient} id='form-add-client'>
        <p>Adicionar Cliente</p>
        <input type='text' onChange={(e) => setName(e.target.value)} placeholder='Nome do cliente' />
        <label>
          <span id='label-file-text-add-client'>Imagem: não anexada</span>
          <input type='file' onChange={handleImagemSelecionada} id='input-file-text-add-client' />
        </label>
        <button type='submit'>Adicionar Cliente</button>
      </form>

      <div id='div-cadastrando-client'>
        <p>Cadastrando Cliente, aguarde...</p>
      </div> 
      <ButtomClients />
    </div>
  )
} 
  
export default AddClient;