//Assets

//Imports
import './Styles/Category.style.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import firebaseConfig from '../Services/FirebaseConfig';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//Components/Sections
import CreateCardProducts from '../Components/CardProduct/CreateCardProduct';

//Configs
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const Category = () => {
  const { categoria } = useParams();
  const [resultados, setResultados] = useState([]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  });

  useEffect(() => {
    const buscarResultados = async () => {
      try {
        const querySnapshot = await db.collection('products')
          .where('category', '==', categoria)
          .where('active', '==', true)
          .get();
        const resultados = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setResultados(resultados);
      } catch (error) {
        alert(`Erro ao buscar resultados: ${error}`);
      }
    };
    buscarResultados();
  }, [categoria]);

    return (
      <div id='Category'>
        <div className='title-page'>
          <h2>{categoria}</h2>
        </div>
        { resultados.length > 0 ?
        <ul className='box-itens-category'>
          {resultados.map((resultado) => (
            <CreateCardProducts id={resultado.id} name={resultado.name} img={resultado.img} />
          ))}
        </ul>
        :
          <div className='nenhum-produto'>
              <p>Nenhum produto encontrado</p>
          </div>
        }
      </div>
    );
}
  
export default Category;