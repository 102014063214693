//Imports
import { Link } from 'react-router-dom';
import './Styles/ProductsHome.style.css';
import firebaseConfig from '../Services/FirebaseConfig';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useState, useEffect } from 'react';

//Configs
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const ProductsHome = () => {
  const [destaques, setDestaques] = useState([]);

  useEffect(() => {
    const buscarResultados = async () => {
      try {
        const querySnapshot = await db.collection('products')
          .where('destaque', '==', true)
          .where('active', '==', true)
          .limit(8) // Adicionando um limite de 8 itens
          .get();
        const destaquesResult = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDestaques(destaquesResult);
      } catch (error) {
        alert(`Erro ao buscar resultados: ${error}`);
      }
    };
    buscarResultados();
  }, []);

    const CreateCardItem = ({link, imagem}) => {
        return(
            <Link to={`/produto/${link}`} className='link-inside-products-home'> 
                <img src={imagem} alt='Imagem de produto' />
            </Link>
        )
    }

    return (
        <div id="products-home">
            <div>
                <h2 className='title-products-home'>Nossas Soluções</h2>
                <div className='products-home-box'> 
                    {destaques.map((destaque) => (
                       <CreateCardItem link={destaque.id} imagem={destaque.img} />
                    ))}
                </div>
                <div className='btn-box-products-home'>
                  <Link to={'/produtos/todos'}>Ver todos</Link>
                </div>
           </div>
        </div>        
    )
}

export default ProductsHome; 