import './LoadingElement.style.css';

const LoadingElement = () => {
    return (
        <div className='loading-product'>
            <div></div>
        </div>
    )
};

export default LoadingElement;