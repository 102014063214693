//Imports
import { Route, Routes, Navigate, HashRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { auth } from '../src/Services/FirebaseConfig';

//Pages
import Login from './AdmRoutes/Login'
import Adm from './AdmRoutes/AdmHome'
import Register from "./AdmRoutes/Register";
import AdmClients from './AdmRoutes/AdmClients';
import AddClient from './AdmRoutes/AddClient';
import AdmProducts from './AdmRoutes/AdmProducts';
import AdmBanners from './AdmRoutes/AdmBanners';
import AddBanner from './AdmRoutes/AddBanner';
import EditBanner from './AdmRoutes/EditBanner.js';
import Addproducts from './AdmRoutes/AddProducts';
import EditProduct from './AdmRoutes/EditProducts';
import Home from './PublicRoutes/Home';
import Product from './PublicRoutes/Product';
import Category from './PublicRoutes/Category';
import AllProducts from './PublicRoutes/AllProducts';
import SearchPage from './PublicRoutes/SearchPage';
import PageNotFound from './PublicRoutes/PageNotFound';

//Components
import Navbar from './Components/NavBar/Navbar';
import Footer from './Components/Footer/Footer';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isPublic, setIsPublic] = useState(true);

  useEffect(() => {
    // Verifica se o usuário está autenticado
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    });
  }, []);

  useEffect(() => {
    const isAdmRoute = window.location.hash.includes('/adm');
    setIsPublic(!isAdmRoute);
  }, []);

  return (
    <HashRouter>
      {isPublic ? <Navbar /> : ''}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/produto/:id' element={<Product />} />
        <Route path='/produtos/:categoria' element={<Category />} />
        <Route path='/produtos/todos' element={<AllProducts />} />
        <Route path='/buscar/:termo' element={<SearchPage />} />

        <Route path='/adm/login' element={<Login />} setLoggedIn={setLoggedIn}/>
        <Route path="/adm/home" element={ (loggedIn ? <Adm /> : <Navigate to="/adm/login" />) } />
        <Route path="/adm/clients" element={ (loggedIn ? <AdmClients /> : <Navigate to="/adm/login" />) } />
        <Route path="/adm/add-client" element={ (loggedIn ? <AddClient /> : <Navigate to="/adm/login" />) } />
        <Route path="/adm/banners" element={ (loggedIn ? <AdmBanners /> : <Navigate to="/adm/login" />) } />
        <Route path="/adm/add-banner" element={ (loggedIn ? <AddBanner /> : <Navigate to="/adm/login" />) } />
        <Route path="/adm/edit-banner/:id" element={ (loggedIn ? <EditBanner /> : <Navigate to="/adm/login" />) } />
        <Route path="/adm/products" element={ (loggedIn ? <AdmProducts /> : <Navigate to="/adm/login" />) } />
        <Route path="/adm/add-product" element={ (loggedIn ? <Addproducts /> : <Navigate to="/adm/login" />) } />
        <Route path="/adm/edit-product/:id" element={ (loggedIn ? <EditProduct /> : <Navigate to="/adm/login" />) } />
        <Route path="/adm/register" element={ (loggedIn ? <Register /> : <Navigate to="/adm/login" />) } />
        
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      {isPublic ? <Footer /> : ''}
    </HashRouter>
  );
}

export default App;